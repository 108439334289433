<script>
  /**
   * A generic material design circular progress indicator.
   */
</script>

<!-- https://github.com/material-components/material-components-web/issues/30 -->
<svg class="mdc-circular-progress" viewBox="25 25 50 50">
  <circle
    class="mdc-circular-progress__path"
    cx="50"
    cy="50"
    r="20"
    fill="none"
    stroke-width="5"
    stroke-miterlimit="10"
  />
</svg>

<style>
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124;
    }
  }

  .mdc-circular-progress {
    position: relative;
    width: 24px;
    height: 24px;
    animation: rotate 2s linear infinite;
  }

  .mdc-circular-progress__path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
    stroke: var(--gigxr-theme-primary-1d);
  }
</style>
