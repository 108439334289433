<script>
  import Button, { Label as ButtonLabel } from '@smui/button';
</script>

<Button id="session-participant-attended-button" class="session-participant-attended-button" disabled>
  <ButtonLabel>Attended</ButtonLabel>
</Button>

<style>
  :global(.session-participant-attended-button) {
    opacity: 0.5 !important;
  }
</style>
