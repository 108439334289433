<script>
  import Icon from '@smui/textfield/icon/index';
</script>

<div class="gigxr-dialog-icon">
  <Icon class="material-icons">
    <slot>error_outline</slot>
  </Icon>
</div>

<style>
  :global(.gigxr-dialog-icon) {
    color: var(--gigxr-theme-secondary-2c);
    text-align: center;
    margin-bottom: 1em !important;
  }

  :global(.gigxr-dialog-icon .material-icons) {
    font-size: 40px;
    font-weight: 100;
  }
</style>
