<script>
</script>

<div class="available-session-card-container">
  <slot />
</div>

<style>
  .available-session-card-container {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    justify-items: center;
    margin-bottom: 20px;
    margin-top: 0.75em;
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    .available-session-card-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: 1100px) {
    .available-session-card-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
</style>
