<script>
  import { navigate } from 'svelte-routing';
  import Button, { Label, Icon } from '@smui/button';
  import { isUnity, isDeveloper } from '../../util/unity';
  import { getAccountId } from '../../util/account';
  import { account } from '../../stores/core-store';
  import { fetchInstitutionLeaf } from '../../util/api/institutions';
  import { onMount } from 'svelte';
  import Cookies from 'js-cookie';
  import { BASE_CONVERSATIONS_URL } from '../../config';

  export let session;
  export let disabled = false;

  var institutionLeaf = null;
  let jwt = '';
  onMount(async () => {
    institutionLeaf = await fetchInstitutionLeaf($account.institutionId);
    jwt = Cookies.get('jwt');
  });

  let cardLink = '';
  let isConversations = false;
  $: if (session) {
    if (session.clientApp && session.clientApp.clientAppName && session.clientApp.clientAppName === 'Conversations') {
      cardLink = `${BASE_CONVERSATIONS_URL}/room/${session.sessionId}?accessToken=${jwt}`;
      isConversations = true;
    } else if (
      (isUnity() && session.createdById !== getAccountId()) ||
      (isUnity() && isDeveloper() && session.createdById === getAccountId()) ||
      (isUnity() && institutionLeaf?.canMobileCreateSessions)
    ) {
      cardLink = `/sessions/lobby/${session.sessionId}`;
    } else {
      cardLink = `/sessions/join/${session.sessionId}?tab=qr`;
    }
  }

  function clickHandler(event) {
    navigate(cardLink);
  }
</script>

<Button id="join-session-button" on:click={clickHandler} variant="unelevated" class="join-session-button" {disabled}>
  {#if isConversations}
    <Label class="join-session-button__label">Join</Label>
  {:else}
    <Icon class="material-icons" />
    <Label class="join-session-button__label">Join</Label>
  {/if}
</Button>

<style>
  :global(.join-session-button) {
    height: 45px;
    border-radius: 6px;
  }

  :global(.join-session-button:not(:disabled)) {
    background-color: var(--gigxr-theme-secondary-3c);
  }

  :global(.join-session-button:disabled) {
    color: #fff !important;
  }

  :global(.join-session-button .material-icons) {
    background-image: url('/assets/qr-icon.svg');
    background-repeat: no-repeat;
    background-size: 30px 30px;
    width: 30px;
    height: 30px;
  }

  :global(.join-session-button__label) {
    text-transform: uppercase;
    font-size: 16px;
  }

  @media (min-width: 1100px) {
    :global(.join-session-button) {
      border-radius: 6px 6px 0 0;
    }
  }
</style>
