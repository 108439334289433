<script>
</script>

<div class="unauthenticated-page__content">
  <slot />
</div>

<style>
  .unauthenticated-page__content {
    text-align: center;
    margin: 0 auto;
    padding: 5px 20px 20px 20px;
    max-width: 650px;
  }

  @media (min-width: 1100px) {
    .unauthenticated-page__content {
      max-width: 650px;
    }
  }
</style>
