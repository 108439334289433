<script>
  import PrimaryContent from '../PrimaryContent.svelte';
</script>

<div class="session-in-progress-banner">
  <PrimaryContent>
    <div class="session-in-progress-banner__text">Session In Progress</div>
  </PrimaryContent>
</div>

<style>
  .session-in-progress-banner {
    position: relative;
    top: -30px;
    background: var(--gigxr-theme-secondary-3c);
    width: 100%;
    /*margin-bottom: 20px;*/
    height: 20px;
  }

  .session-in-progress-banner__text {
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-size: 0.8em;
  }
</style>
