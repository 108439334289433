<script>
  // This component represents a button that looks like a plain hyperlink.
</script>

<button on:click>
  <slot />
</button>

<style>
  button {
    display: inline;
    border: 0;
    padding: 0;
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
  }
</style>
