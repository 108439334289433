<script>
</script>

<div class="unauthenticated-page">
  <slot />
</div>

<style>
  @media (min-width: 1100px) {
    .unauthenticated-page {
      min-height: calc(100vh - 15px);
      display: grid;
      place-items: center;
    }
  }
</style>
