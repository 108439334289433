<script>
  import { onMount } from 'svelte';
  import { title, breadcrumbPaths, account } from '../stores/core-store';
  import PrimaryContent from '../components/PrimaryContent.svelte';
  import Tab, { Label } from '@smui/tab';
  import TabBar from '@smui/tab-bar';
  import SecondaryBackgroundWrapper from '../components/SecondaryBackgroundWrapper.svelte';
  import Cookies from 'js-cookie';
  import { role } from '../stores/core-store';
  import { createSession, fetchSessionPlans, SessionPermission } from '../util/api/sessions';
  import { BASE_CONVERSATIONS_URL } from '../config';
  import Button from '@smui/button';
  import { APPS_MAPPING_BY_ID } from '../util/client-applications';

  title.set('Conversations');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Conversations',
      location: '/conversations',
    },
  ]);

  let activeTab = 'About';
  let tabs = ['About'];
  let jwt = '';
  let sessionPlans = [];

  onMount(async () => {
    jwt = Cookies.get('jwt');

    if ($role === 'InstitutionAdmin') {
      tabs.push('Credits');
    }

    sessionPlans = await fetchSessionPlans();
  });

  async function handleLaunchCAISession() {
    // Grab empty sessionPlan for Conversations App
    const sessionPlan = sessionPlans.find(
      (sessionPlan) =>
        sessionPlan.clientAppId === APPS_MAPPING_BY_ID.CONVERSATIONS &&
        sessionPlan.sessionName === 'Empty Session' &&
        sessionPlan.gigXrBranded === true,
    );
    const session = {};
    const date = new Date();
    date.setSeconds(0);
    date.setMilliseconds(0);
    let todayIso = date.toISOString();
    todayIso = todayIso.substring(0, todayIso.length - 1);

    session.sessionName = `${$account.firstName}'s Conversation'`;
    session.clientAppId = APPS_MAPPING_BY_ID.CONVERSATIONS;
    session.classId = sessionPlan.classId;
    session.description = sessionPlan.description;
    session.sessionPermission = SessionPermission.OPEN_TO_INSTITUTION;
    session.lessonDate = todayIso;
    session.clientAppVersion = sessionPlan.clientAppVersion || '';
    session.hmdJson = sessionPlan.hmdJson;
    session.saved = true;

    const newSession = await createSession(session);

    window.open(`${BASE_CONVERSATIONS_URL}/room/${newSession.sessionId}?accessToken=${jwt}`, '_blank');
  }

  // https://github.com/hperrin/svelte-material-ui/issues/67
  $: tabPromise = Promise.resolve(tabs);
</script>

<PrimaryContent>
  {#await tabPromise then tabs}
    <TabBar {tabs} let:tab bind:active={activeTab} class="gigxr-tabs">
      <Tab {tab} minWidth>
        <Label>{tab}</Label>
      </Tab>
    </TabBar>
  {/await}
</PrimaryContent>

<div hidden={activeTab !== 'About'}>
  <PrimaryContent>
    <div class="about-tab__container">
      <p class="about-tab__title">
        The GigXR Conversations Platform is the hub for realistic AI generated speech across multiple applications.
      </p>
      <div class="conversations-description__container">
        <div class="conversations-description__section-container">
          <div class="conversation-descriotion__section-icon-container">
            <img src="/icons/dialog-icon.svg" alt="Conversations Icon" class="conversation-descriotion__section-icon" />
          </div>
          <p class="conversations-description__section-title">Freeform Dialog</p>
          <div class="conversations-description__section-divider" />
          <p class="conversations-description__section-description">
            Patients will always respond within the respective medical pathology while also able to respond to any
            non-medical question.
          </p>
        </div>
        <div class="conversations-description__section-container">
          <div class="conversation-descriotion__section-icon-container">
            <img src="/icons/patient-icon.svg" alt="Patient Icon" class="conversation-descriotion__section-icon" />
          </div>
          <p class="conversations-description__section-title">60+ patients</p>
          <div class="conversations-description__section-divider" />
          <p class="conversations-description__section-description">
            Typical and atypical standardized patients are all accessible at the click of a button. For example,
            respiratory, cardio, IBS, glaucoma, medical error, de-escalation and many many more patients are within
            Conversations!
          </p>
        </div>
        <div class="conversations-description__section-container">
          <div class="conversation-descriotion__section-icon-container">
            <img
              src="/icons/people-group-icon.svg"
              alt="People Group Icon"
              class="conversation-descriotion__section-icon"
            />
          </div>
          <p class="conversations-description__section-title">Single or Multi-User</p>
          <div class="conversations-description__section-divider" />
          <p class="conversations-description__section-description">
            Depending on which application you are using, you may create learning experiences with one or more users at
            a time. Engage in multi-user conversations with our virtual patients for enhanced team learning.
          </p>
        </div>
      </div>
    </div>
  </PrimaryContent>
  <div class="conversations-apps__container">
    <PrimaryContent>
      <p class="conversations-apps__title">Try it now!</p>
      <div class="conversations-apps__sections-container">
        <div class="conversations-apps__section">
          <div class="conversations-apps__section-image-wrapper">
            <img src="/assets/conversations-web-image.png" alt="Coversations" />
          </div>
          <div class="conversations-apps__section-bottom-section-wrapper">
            <img
              src="/icons/conversations-logo-black.svg"
              alt="Conversations Icon"
              class="conversations-apps__section-bottom-section-image"
            />
            <div class="conversations-apps__section-bottom-section-content-wrapper">
              <p class="conversations-apps__section-bottom-section-title">Conversations Web</p>
              <p>Engage in individual or multi-user patient conversations directly from your web browser.</p>
              <!-- svelte-ignore a11y-missing-attribute -->
              <Button
                id="launch-cai-session-button"
                class="gigxr-button"
                variant="unelevated"
                on:click={handleLaunchCAISession}
              >
                <Label>Launch Web App</Label>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PrimaryContent>
  </div>
</div>

<div hidden={activeTab !== 'Credits'}>
  <SecondaryBackgroundWrapper>
    <PrimaryContent>Credits</PrimaryContent>
  </SecondaryBackgroundWrapper>
</div>

<style>
  .about-tab__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .conversations-description__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  .conversations-description__section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 16rem;
    margin: 1.5rem 0;
    text-align: center;
  }

  .conversations-description__section-title {
    font-size: 1.375rem;
    font-weight: bold;
    margin: 1rem 0;
  }

  .conversations-description__section-description {
    font-size: 1rem;
    margin: 0;
  }

  .about-tab__title {
    margin: 3rem 0rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  .conversation-descriotion__section-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #bce100;
  }

  .conversations-description__section-divider {
    width: 5rem;
    height: 2px;
    background-color: #bce100;
    margin-bottom: 1rem;
  }

  .conversations-apps__container {
    background-color: #e1e1f3;
    padding: 1.875rem 0;
    position: relative;
  }

  .conversations-apps__title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .conversations-apps__sections-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }

  .conversations-apps__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: white;
    border-radius: 0.5rem;
    width: 100%;
  }

  .conversations-apps__section-image-wrapper {
    background-image: url('/assets/line-grid-image.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
    background-clip: border-box;
    width: 100%;
    height: 17.875rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 16px;
  }

  .conversations-apps__section-image-wrapper img {
    max-width: 100%;
  }

  .conversations-apps__section-bottom-section-wrapper {
    position: relative;
    width: 100%;
  }

  .conversations-apps__section-bottom-section-image-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: black;
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    padding: 0.25rem;
  }

  .conversations-apps__section-bottom-section-image-container img {
    width: 100%;
  }

  .conversations-apps__section-bottom-section-image {
    position: absolute;
    width: 5rem;
    height: 5rem;
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 50%;
  }

  .conversations-apps__section-bottom-section-content-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 2rem 1rem 2rem;
  }

  .conversations-apps__section-bottom-section-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
  }

  .conversations-apps__section-bottom-section-stores-container {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .conversations-apps__section-bottom-section-store {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .conversations-apps__section-bottom-section-store-image {
    cursor: pointer;
  }

  @media (min-width: 1100px) {
    .conversations-description__container {
      gap: 5rem;
    }
    .conversations-apps__section-bottom-section-stores-container {
      flex-wrap: nowrap;
    }

    .conversations-apps__section-bottom-section-store {
      width: 50%;
      justify-content: flex-end;
    }
  }

  @media (min-width: 768px) {
    .conversations-apps__container {
      padding: 3rem 0;
    }
    .conversations-apps__title {
      margin-bottom: 3rem;
    }
    .about-tab__title {
      margin: 3rem 9rem;
    }
    .conversations-apps__section {
      width: 50%;
    }

    .conversations-apps__sections-container {
      flex-wrap: nowrap;
    }

    .conversations-apps__section-bottom-section-content-wrapper {
      padding: 4rem 4rem 2rem 4rem;
    }

    .conversations-apps__section-bottom-section-image {
      width: 6.25rem;
      height: 6.25rem;
      top: -3.125rem;
    }

    .conversations-apps__section-bottom-section-image-container {
      width: 6.25rem;
      height: 6.25rem;
      top: -3.125rem;
    }

    .conversation-descriotion__section-icon-container {
      width: 6.25rem;
      height: 6.25rem;
    }
  }

  @media (min-width: 480px) and (max-width: 767px) {
    .conversations-apps__section {
      width: 65%;
    }
  }
</style>
