<script>
</script>

<div class="primary-content">
  <slot />
</div>

<style>
  .primary-content {
    max-width: 700px;
    position: relative;
    margin: 0 auto;
    padding: 0 1em;
  }

  @media (min-width: 1100px) {
    .primary-content {
      padding-left: 105px;
      padding-right: 25px;
      max-width: 1100px;
    }
  }
</style>
