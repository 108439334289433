<script>
  import { Actions as DialogActions } from '@smui/dialog';
</script>

<DialogActions>
  <slot />
</DialogActions>

<style>
  @media (max-width: 1099px) {
    :global(.gigxr-dialog .mdc-dialog__actions) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (min-width: 1100px) {
    :global(.gigxr-dialog .mdc-dialog__actions) {
      justify-content: center;
    }
  }
</style>
