<script>
  import CircularProgress from './CircularProgress.svelte';
</script>

<div class="button-circular-progress">
  <CircularProgress />
</div>

<style>
  .button-circular-progress {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
