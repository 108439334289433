<script>
</script>

<div class="work-in-progress">This page is a work in progress.</div>

<style>
  .work-in-progress {
    border: thin solid var(--gigxr-theme-secondary-4);
    background: var(--gigxr-theme-secondary-4);
    color: #fff;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 5px;
    display: block;
    width: 300px;
    margin: 0 auto 1em;
    text-align: center;
  }
</style>
