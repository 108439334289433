<script>
</script>

<div class="secondary-background-wrapper">
  <slot />
</div>

<style>
  .secondary-background-wrapper {
    background: #eef2f4;
    border-top: 1px solid #ced3d4;
    border-top: thin solid var(--gigxr-theme-primary-1a);
    padding: 30px 0;
    position: relative;
  }
</style>
