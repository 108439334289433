<script>
</script>

<div class="session-content-manager-progress-circle-container">
  <slot />
</div>

<style>
  .session-content-manager-progress-circle-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
  }
</style>
