<script>
  import { onMount } from 'svelte';
  import List, { Item, Text, Meta, Graphic } from '@smui/list';
  import IconButton, { Icon } from '@smui/icon-button';
  import { getAccountId } from '../../util/account';
  import Textfield from '@smui/textfield';
  import Button, { Label } from '@smui/button';
  import { fade } from 'svelte/transition';
  import { v4 as uuidv4 } from 'uuid';
  import { account } from '../../stores/core-store';

  export let sessionCreatedById;
  export let scene;

  onMount(async () => {});

  function addLabel(labelContent) {
    scene.sceneLabels = [
      ...(scene.sceneLabels || []),
      {
        labelId: uuidv4(),
        labelContent,
        creatorId: getAccountId(),
        creatorName: `${$account.firstName} ${$account.lastName}`,
        activeClip: scene.title,
      },
    ];
  }

  function removeLabel(labelToDelete) {
    scene.sceneLabels = scene.sceneLabels.filter((label) => label !== labelToDelete);
  }

  function focusOnCreate(element) {
    element.focus();
  }

  const fadeConfig = {
    duration: 200,
  };
</script>

<div>
  <ul class="scene-labels">
    {#if scene.sceneLabels && scene.sceneLabels.length > 0}
      {#each scene.sceneLabels as label, index (index)}
        <li in:fade|local={fadeConfig}>
          <Textfield
            class="gigxr-input"
            bind:value={label.labelContent}
            variant="filled"
            disabled={sessionCreatedById !== getAccountId()}
            use={[focusOnCreate]}
          />
          {#if label.anchorPoint}
            <Graphic class="material-icons" title="Label has been placed in app">location_on</Graphic>
          {:else}
            <Graphic />
          {/if}
          {#if sessionCreatedById === getAccountId()}
            <IconButton class="material-icons" on:click={() => removeLabel(label)}>close</IconButton>
          {/if}
        </li>
      {/each}
    {:else}
      <li>There are no labels for this clip.</li>
    {/if}
    {#if sessionCreatedById === getAccountId()}
      <li class="scene-labels__new-label">
        <Button class="gigxr-button-link" on:click={() => addLabel('')}>
          <Label>Add New Label to Clip</Label>
        </Button>
      </li>
    {/if}
  </ul>
</div>

<style>
  .scene-labels {
    margin: 0;
    padding-left: 0;
  }

  .scene-labels li {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }

  .scene-labels li:last-child {
    margin-bottom: 0.5em;
  }

  .scene-labels__new-label {
    margin-left: -8px;
  }

  :global(.scene-labels .mdc-list-item__graphic) {
    margin: 0 10px 0 20px;
  }
</style>
