<script>
  import { onMount } from 'svelte';
  import {
    title,
    breadcrumbPaths,
    snackbarMessage,
    snackbar,
    urlHistory as storeUrlHistory,
  } from '../../stores/core-store';
  import SavedSessionCard from '../../components/sessions/SavedSessionCard.svelte';
  import FilterComponent from '../../components/FilterComponent.svelte';
  import { CARD_VIEW_COUNT_PER_PAGE } from '../../util/constants';
  import PrimaryContent from '../../components/PrimaryContent.svelte';
  import SecondaryBackgroundWrapper from '../../components/SecondaryBackgroundWrapper.svelte';
  import MobileListPageControls from '../../components/MobileListPageControls.svelte';
  import ListPageCount from '../../components/ListPageCount.svelte';
  import FilterButton from '../../components/FilterButton.svelte';
  import DesktopListPageControls from '../../components/DesktopListPageControls.svelte';
  import AddSessionButton from '../../components/sessions/AddSessionButton.svelte';
  import ListPageViewMoreButton from '../../components/ListPageViewMoreButton.svelte';
  import LoadingView from '../../components/LoadingView.svelte';
  import { fetchClasses } from '../../util/api/classes';
  import { fetchVersions } from '../../util/api/versions';
  import { fetchSessions, fetchSavedSessions, patchSessions } from '../../util/api/sessions';
  import {
    SessionFilterByClassSet,
    SessionFilterByClientAppSet,
    SessionFilterByLessonDateSet,
    SessionFilterIncludeArchivedSet,
    SessionFilterIncludeVersionsSet,
    SessionSortBySet,
  } from '../../util/filters/session-list-filters';
  import { fetchClientApps, fetchLicensedClientApps } from '../../util/api/client-apps';
  import ListPageNoResultsMessage from '../../components/ListPageNoResultsMessage.svelte';
  import ListPageBatchSelect from '../../components/ListPageBatchSelect.svelte';
  import ListPageActionControls from '../../components/ListPageActionControls.svelte';
  import ListPageSelectionText from '../../components/ListPageSelectionText.svelte';
  import ListPageCardGridHeader from '../../components/ListPageCardGridHeader.svelte';
  import ListPageCardGridItem from '../../components/ListPageCardGridItem.svelte';
  import ListPageCardGridCheckbox from '../../components/ListPageCardGridCheckbox.svelte';
  import ListPageSearch from '../../components/ListPageSearch.svelte';
  import ListPageMobileSearchButton from '../../components/ListPageMobileSearchButton.svelte';

  title.set('Session List');
  breadcrumbPaths.set([
    {
      name: 'Dashboard',
      location: '/',
    },
    {
      name: 'Session List',
      location: '/sessions/list',
    },
  ]);

  const entity = 'Session';
  let loading = true;
  let filterComponent;
  let filteredSessions = [];
  let savedSessions = [];
  let filterBySets = [];
  let filteredAndSearchedSessions = [];
  let showMobileSearch = false;
  let sortBySet = undefined;
  let defaultFilters = {
    [`sort${entity}ByDate`]: 'Lesson Date (Latest)',
    [`filter${entity}ByLessonDate`]: 'All',
    [`filter${entity}ByClass`]: '*',
    [`filter${entity}ByClientApp`]: 'All',
    [`filter${entity}ByVersion`]: '*',
    [`filter${entity}ByArchived`]: false,
  };

  // Batch select
  let showBatchActions = false;
  let selectedValues = [];
  let allSelectedIncludingNotShown = false;
  let listPageActionControlsConfig = {};
  let urlHistory = [];

  storeUrlHistory.subscribe((history) => {
    urlHistory = history;
  });

  let displayedItems = CARD_VIEW_COUNT_PER_PAGE;
  $: remainingItems = filteredAndSearchedSessions.length - displayedItems;

  onMount(async () => {
    let versions;
    let classes;
    let clientApps;
    [savedSessions, classes, versions, clientApps] = await Promise.all([
      fetchSavedSessions(true),
      fetchClasses(),
      fetchVersions(),
      fetchClientApps(),
    ]);

    // Only show client app filters for sessions that exist in this data set.
    const clientAppIdsToShow = new Set();
    savedSessions.forEach((session) => {
      clientAppIdsToShow.add(session.clientAppId);
    });
    clientApps = clientApps.filter((app) => clientAppIdsToShow.has(app.clientAppId));
    versions = [...new Set(savedSessions.map((x) => x.clientAppVersion).filter((x) => x != null))];

    // Set default filters from sessionStorage if user is coming from Session View
    if (urlHistory.length > 2 && urlHistory[urlHistory.length - 2].includes('/sessions/view/')) {
      Object.keys(defaultFilters).forEach((defaultFilterKey) => {
        if (sessionStorage.getItem(defaultFilterKey)) {
          const value = sessionStorage.getItem(defaultFilterKey);
          defaultFilters[defaultFilterKey] = value === 'false' ? false : value === 'true' ? true : value;
        }
      });
    }

    filterBySets = [
      SessionFilterByLessonDateSet(defaultFilters[`filter${entity}ByLessonDate`]),
      SessionFilterByClassSet(classes, defaultFilters[`filter${entity}ByClass`]),
      SessionFilterByClientAppSet(clientApps, defaultFilters[`filter${entity}ByClientApp`]),
      SessionFilterIncludeVersionsSet(versions, defaultFilters[`filter${entity}ByVersion`]),
      SessionFilterIncludeArchivedSet(defaultFilters[`filter${entity}ByArchived`]),
    ];

    sortBySet = SessionSortBySet(defaultFilters[`sort${entity}ByDate`]);

    initializeListPageActionControlsConfig();

    loading = false;
  });

  // Real selected values takes into account options like choosing to select all hidden rows, too.
  let realSelectedSessions = [];
  $: {
    const realSelectedValues = selectedValues;
    const totalCount = filteredAndSearchedSessions.length;
    const shownCount = Math.min(displayedItems, filteredAndSearchedSessions.length);

    if (totalCount > shownCount && realSelectedValues.length > shownCount && allSelectedIncludingNotShown) {
      // All values are selected AND the option to include hidden rows is checked.
      realSelectedSessions = filteredAndSearchedSessions.map((session) => session.sessionId);
    } else {
      realSelectedSessions = realSelectedValues;
    }
  }

  // This reactive block will clear the mobile batch selections when it is closed.
  $: if (!showBatchActions) {
    selectedValues = [];
  }

  async function bulkSessionPatch(patchToApply) {
    const patches = [];
    const patchesById = new Map();

    let sessionIdsToPatch = realSelectedSessions;

    sessionIdsToPatch.forEach((sessionId) => {
      const patch = {
        sessionId,
        ...patchToApply,
      };
      patches.push(patch);
      patchesById.set(sessionId, patch);
    });

    await patchSessions(patches);

    // Update data on the client side so we don't have to fetch again.
    savedSessions.forEach((session) => {
      if (patchesById.has(session.sessionId)) {
        Object.assign(session, patchesById.get(session.sessionId));
      }
    });
    filteredAndSearchedSessions = filteredAndSearchedSessions;

    return patches.length;
  }

  function initializeListPageActionControlsConfig() {
    listPageActionControlsConfig = {
      'Make Archived': {
        callback: async () => {
          const count = await bulkSessionPatch({
            sessionStatus: 'Archived',
          });
          snackbarMessage.set(`${count} Session${count === 1 ? '' : 's'} archived!`);
          $snackbar.open();
        },
      },
      'Make Unarchived': {
        callback: async () => {
          const count = await bulkSessionPatch({
            sessionStatus: 'Ended',
          });
          snackbarMessage.set(`${count} Session${count === 1 ? '' : 's'} unarchived!`);
          $snackbar.open();
        },
      },
    };
  }

  const searchValueFunctions = {
    sessionName: (session) => session.sessionName,
  };

  // This reactive block controls the header checkbox.
  $: {
    const headerCheckbox = document.getElementById('checkbox-header');
    if (headerCheckbox) {
      if (selectedValues.length >= Math.min(displayedItems, filteredAndSearchedSessions.length)) {
        headerCheckbox.checked = true;
        headerCheckbox.indeterminate = false;
      } else if (selectedValues.length === 0) {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = false;
      } else {
        headerCheckbox.checked = false;
        headerCheckbox.indeterminate = true;
      }
    }
  }
</script>

<PrimaryContent>
  <MobileListPageControls>
    <div slot="left">
      {#if showMobileSearch}
        <ListPageSearch
          unfilteredList={filteredSessions}
          bind:filteredList={filteredAndSearchedSessions}
          valueFunctions={searchValueFunctions}
          placeholder="Search Sessions"
          on:gigxr::clear={() => (showMobileSearch = false)}
        />
      {:else}
        <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
        <ListPageCount
          {loading}
          count={filteredAndSearchedSessions.length}
          singularLabel="Session"
          pluralLabel="Sessions"
        />
      {/if}
    </div>
    <div slot="right">
      {#if showMobileSearch}
        <AddSessionButton disabled={showBatchActions} />
      {:else}
        <ListPageMobileSearchButton on:click={() => (showMobileSearch = true)} disabled={showBatchActions} />
        <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
        <AddSessionButton disabled={showBatchActions} />
      {/if}
    </div>
  </MobileListPageControls>

  <DesktopListPageControls>
    <div slot="left">
      <ListPageBatchSelect on:click={() => (showBatchActions = !showBatchActions)} />
      <ListPageCount
        {loading}
        count={filteredAndSearchedSessions.length}
        singularLabel="Session"
        pluralLabel="Sessions"
      />
      <FilterButton on:click={filterComponent.toggle} disabled={showBatchActions} />
    </div>
    <div slot="right">
      <div class="list-page-search__wrapper">
        <ListPageSearch
          unfilteredList={filteredSessions}
          bind:filteredList={filteredAndSearchedSessions}
          valueFunctions={searchValueFunctions}
          placeholder="Search Sessions"
        />
      </div>
      <AddSessionButton disabled={showBatchActions} />
    </div>
  </DesktopListPageControls>
</PrimaryContent>

<FilterComponent
  bind:this={filterComponent}
  unfilteredList={savedSessions}
  bind:filteredList={filteredSessions}
  {sortBySet}
  {entity}
  {filterBySets}
/>

<SecondaryBackgroundWrapper>
  <PrimaryContent>
    {#if loading}
      <LoadingView />
    {:else}
      {#if showBatchActions}
        <ListPageActionControls actions={listPageActionControlsConfig} selectedCount={selectedValues.length} />

        <ListPageSelectionText
          totalCount={filteredAndSearchedSessions.length}
          shownCount={Math.min(displayedItems, filteredAndSearchedSessions.length)}
          selectedCount={selectedValues.length}
          singularName="Session"
          pluralName="Sessions"
          bind:allSelectedIncludingNotShown
        />
      {/if}

      {#if filteredAndSearchedSessions.length === 0}
        <ListPageNoResultsMessage>
          <h3>There are no sessions to display.</h3>
        </ListPageNoResultsMessage>
      {:else}
        <div class="session-list">
          <ListPageCardGridItem hide={!showBatchActions}>
            <ListPageCardGridHeader
              on:change={(event) => {
                if (event.target.checked) {
                  selectedValues = filteredAndSearchedSessions
                    .slice(0, displayedItems)
                    .map((session) => session.sessionId);
                } else {
                  selectedValues = [];
                }
              }}
              compact={true}
            />
          </ListPageCardGridItem>
          {#each filteredAndSearchedSessions as session, index (session.sessionId)}
            {#if index < displayedItems}
              <ListPageCardGridItem>
                <ListPageCardGridCheckbox
                  value={session.sessionId}
                  bind:group={selectedValues}
                  show={showBatchActions}
                />
                <SavedSessionCard {session} compact={showBatchActions} bind:selectedValues />
              </ListPageCardGridItem>
            {/if}
          {/each}
        </div>
      {/if}

      {#if remainingItems > 0}
        <ListPageViewMoreButton
          itemName="Saved Sessions"
          {remainingItems}
          on:click={() => (displayedItems += CARD_VIEW_COUNT_PER_PAGE)}
        />
      {/if}
    {/if}
  </PrimaryContent>
</SecondaryBackgroundWrapper>

<style>
  .session-list {
    display: grid;
    column-gap: 30px;
    justify-items: center;
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    .session-list {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }
  }

  @media (min-width: 1100px) {
    .session-list {
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
    }
  }

  .list-page-search__wrapper {
    margin-right: 10px;
  }
</style>
