<script>
</script>

<div class="list-page-card-grid">
  <slot />
</div>

<style>
  @media (min-width: 1100px) {
    .list-page-card-grid {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1099px) {
    .list-page-card-grid {
      display: grid;
      justify-items: center;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
    }
  }
</style>
