<script>
</script>

<div class="list-page-no-results-message">
  <slot />
</div>

<style>
  .list-page-no-results-message {
    text-align: center;
    max-width: 640px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    padding: 30px;
  }
</style>
